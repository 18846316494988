import SiteWrapper from "../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../assets/images/pagerduty_dxable_og.png";

import MdRenderer from "../components/mdrender";
import * as md from "../components/mdrender.module.sass";
import TitleBlock from "../components/title";

import "./readme-japan-it-week-autumn-2021@gen.scss";

const GeneratedReadmeJapanItWeekAutumn2021 = () => {
  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>
          Japan IT Week PagerDuty スタートガイドブック期間限定 無料プレゼント! -
          PagerDuty正規代理店 - 株式会社Digital Stacks
        </title>

        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
        <meta
          property="twitter:description"
          content="監視ツールやアプリからのアラートを確実に担当者に通報する SaaSインシデント対応
プラットフォーム製品"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          name="description"
          content="監視ツールやアプリからのアラートを確実に担当者に通報する SaaSインシデント対応
プラットフォーム製品"
        />

        <meta
          property="og:title"
          content="Japan IT Week PagerDuty スタートガイドブック期間限定 無料プレゼント! - PagerDuty正規代理店 - 株式会社Digital Stacks"
        />

        <meta
          property="og:description"
          content="監視ツールやアプリからのアラートを確実に担当者に通報する SaaSインシデント対応
プラットフォーム製品"
        />
        <meta
          property="og:url"
          content="https://pagerduty.dxable.com/readme-japan-it-week-autumn-2021/"
        />
        <meta
          property="twitter:url"
          content="https://pagerduty.dxable.com/readme-japan-it-week-autumn-2021/"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <TitleBlock
        classNames="md:mb-[80px] mb-[40px]"
        textYellow=""
        textGreen="PagerDuty スタートガイドブック 期間限定 無料プレゼント!"
        overview=""
      />

      <div className="readmeWrapper">
        <div className="readme-container">
          <div className="center-2 mt-[5px] md:mt-[0px]">
            <h2 className="center-title">
              PagerDuty スタートガイドブック <br />
              期間限定 無料プレゼント!
            </h2>
            <p className="center-para">
              監視ツールやアプリからのアラートを確実に担当者に通報する
              SaaSインシデント対応 <br />
              プラットフォーム製品
            </p>
          </div>
          <div className="two-col-media-contents mb-[100px] items-start">
            <div className="media-contents-part">
              <img
                src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/dfbb78c9215ac7f6351345f3a6022caf_b95fa510b8.png"
                alt="PagerDuty スタートガイドブック 期間限定 無料プレゼント!"
              />
            </div>
            <div className="text-part">
              <div className="banner-container mt-0">
                <p className="banner-para">キャンペーンは終了しました。</p>
                <div className="absolute top-[97%] left-[50%] -translate-x-[50%]">
                  <img
                    src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/triangle_Gray_b3e11b2b3d.png"
                    alt="Triangle Gray"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SiteWrapper>
  );
};
export default GeneratedReadmeJapanItWeekAutumn2021;
